@tailwind base;
@tailwind components;
@tailwind utilities;

.base-page {
    .page-title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
}